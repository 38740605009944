const FROM_FULL_ADDRESS_KEYS = [
    'consignee_address',
    'consignee_city',
    'consignor_address',
    'consignor_city',
    'delivery_address',
    'delivery_city',
    'pickup_address',
    'pickup_city',
];

const CONTACT_FIELD_NAMES = [
    'email', 'phone', 'contactperson'
];

export default {
    methods: {
        getIsFromFullAddress(key) {
            return FROM_FULL_ADDRESS_KEYS.includes(key);
        },
        getValueOrDash(val) {
            return val || '–';
        },
        getFullAddressField({ row, key }) {
            try {
                const [type, fieldName] = key.split('_');

                if (!row[`${type}_full_address`]?.id) {
                    return this.getValueOrDash(row[key]);
                }
                
                if (CONTACT_FIELD_NAMES.includes(fieldName)) {
                    return this.getValueOrDash(row[key]);
                }

                if (CONTACT_FIELD_NAMES.find(contactFieldName => contactFieldName === fieldName) && row[`${type}_full_address`].contact) {
                    return this.getValueOrDash(row[`${type}_full_address`].contact[fieldName]);
                }

                if (fieldName === 'city' && row[`${type}_full_address`].city) {
                    return this.getValueOrDash(row[`${type}_full_address`].city.name);
                }

                if (fieldName === 'countrycode' && row[`${type}_full_address`].city) {
                    return this.getValueOrDash(row[`${type}_full_address`].city.country_code);
                }

                return this.getValueOrDash(row[`${type}_full_address`][fieldName]);

            } catch (_) {
                return row[key];
            }
            
        },
    },   
}